import { useStoryblokApi } from '@storyblok/nuxt-2';
import { Logger } from '~/helpers/logger';
import { ISbStories, ISbStory, ISbStoryData } from '@storyblok/js/dist/types';

type GetStrategyType = 'stories' | 'story';
export interface GetParams {
  path?: string;
  params?: object;
}
interface GetParamsWithStrategy extends GetParams {
  strategy: GetStrategyType;
}

export type GetStoryblokApiStoryResult = {
  story: ISbStoryData;
  headers?: any;
};
export type GetStoryblokApiStoriesResult = {
  stories: ISbStoryData[];
  headers?: any;
};

export type GetStoryblokApiResult = GetStoryblokApiStoryResult | GetStoryblokApiStoriesResult;

/**
 * Retrieves stories/story from Storyblok API based on the provided path and parameters.
 * Handles errors and returns null if an error occurs.
 * @param path - The path of the stories/story to retrieve.
 * @param strategy - The strategy to use for retrieving the data. Either `'stories'` or `'story'`.
 * @param params - Additional parameters for the API request.
 * @returns The result and headers obtained from the Storyblok API.
 */
export async function fetchStoryblokApi({ path, strategy, params = {} }: GetParamsWithStrategy): Promise<GetStoryblokApiResult | null> {
  if (!strategy) return null;
  let transformedPath = path;
  if (!path) {
    transformedPath = '';
  }
  if (transformedPath) {
    transformedPath = transformedPath.startsWith('/') ? transformedPath : `/${transformedPath}`;
  }

  const storyblokApi = useStoryblokApi();
  let storiesResult: ISbStoryData[];
  let storyResult: ISbStoryData;

  try {
    const response: ISbStories | ISbStory = await storyblokApi.get(`cdn/stories${transformedPath}`, {
      version: process.env.VSF_STORYBLOK_VERSION,
      ...params,
    });
    if (!response) throw new Error('storyblokApi returned no response');

    const { headers } = response;
    if (strategy === 'stories') {
      storiesResult = (response as ISbStories).data.stories;
      return { stories: storiesResult, headers };
    }
    if (strategy === 'story') {
      storyResult = (response as ISbStory).data.story;
      return { story: storyResult, headers };
    }

    throw new Error('Invalid strategy provided');
  } catch (error) {
    Logger.error(`get-${strategy}/error`, error);
    throw new Error(`${error}`);
  }
}
